<template>
  <!-- Billing Information Card -->
  <div v-if="billData">
    <a-spin v-if="isLoading" size="large" class="loading" />
    <div v-else>
      <h2 class="text-center">{{ $t("label.orderConfirmation") }}</h2>
      <a-card
        :bordered="false"
        class="header-solid h-full"
        :bodyStyle="{ paddingTop: 0, paddingBottom: '16px' }"
      >
        <template #title>
          <h5 class="font-semibold m-0">
            {{ $t("label.billingInformation") }}
          </h5>
        </template>
        <a-card :bordered="false" class="card-billing-info">
          <div class="col-info">
            <a-descriptions>
              <a-descriptions-item :label="$t('form.firstName')">
                {{ billData.getBilling.first_name }}
              </a-descriptions-item>
              <a-descriptions-item :label="$t('form.lastName')">
                {{ billData.getBilling.last_name }}
              </a-descriptions-item>
              <a-descriptions-item :label="$t('label.address')">
                {{ billData.getBilling.address }}
              </a-descriptions-item>
              <a-descriptions-item :label="$t('form.city')">
                {{ billData.getBilling.city }}
              </a-descriptions-item>
              <a-descriptions-item :label="$t('form.country')">
                {{ billData.getBilling.country }}
              </a-descriptions-item>
              <a-descriptions-item :label="$t('form.stateProvince')">
                {{ billData.getBilling.state }}
              </a-descriptions-item>
              <a-descriptions-item :label="$t('label.zipPostal')">
                {{ billData.getBilling.zip }}
              </a-descriptions-item>
              <a-descriptions-item :label="$t('form.phone')">
                {{ billData.getBilling.phone }}
              </a-descriptions-item>
              <a-descriptions-item :label="$t('form.email')">
                {{ billData.getBilling.email }}
              </a-descriptions-item>
            </a-descriptions>
          </div>
        </a-card>
      </a-card>
      <a-card
        :bordered="false"
        class="header-solid h-full mt-15"
        :bodyStyle="{ paddingTop: 0, paddingBottom: '16px' }"
      >
        <template #title>
          <h5 class="font-semibold m-0">
            {{ $t("label.paymentInformation") }}
          </h5>
        </template>
        <a-card :bordered="false" class="card-billing-info">
          <div class="col-info">
            <a-descriptions>
              <a-descriptions-item
                style="font-size: 30px"
                :label="$t('form.paymentType')"
              >
                {{ billData.getBilling.cardType }}
              </a-descriptions-item>
              <a-descriptions-item :label="$t('form.cardNumber')">
                {{ billData.cardNumber }}
              </a-descriptions-item>
              <a-descriptions-item :label="$t('form.cvv')">
                {{ billData.getBilling.cvv }}
              </a-descriptions-item>
              <a-descriptions-item :label="$t('form.expirationDate')">
                {{ billData.getBilling.expirationDate.format("MM/YYYY") }}
              </a-descriptions-item>
              <br />
              <br />
              <div class="container">
                <hr class="color-red" />
                <h5 class="title">{{ $t("label.totalAmount") }}:</h5>
                <a-statistic
                  prefix="₭"
                  :precision="2"
                  v-bind:value="500000"
                  valueStyle=" font-family:'NotoSans'; font-size: 20px; margin-top:7px"
                >
                </a-statistic>
              </div>
            </a-descriptions>
          </div>
        </a-card>
      </a-card>
      <div class="col-action mt-20 ml-10">
        <a-button class="mr-10" @click="edit" type="danger" size="large">
          <span class="text-white">{{ $t("action.EditButton") }}</span>
        </a-button>
        <a-button
          class="ml-10"
          @click="makePayment"
          type="primary"
          size="large"
          style="background-color: green"
        >
          <span class="text-white">{{ $t("button.makePayment") }}</span>
        </a-button>
      </div>
    </div>
  </div>

  <!-- / Billing Information Card -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    ...mapGetters("billing", ["getBilling"]),
    billData() {
      const checkBill = this.getBilling.cardNumber;
      if (!checkBill) {
        return "";
      }
      const first4 = this.getBilling.cardNumber.slice(0, 4);
      const last4 = this.getBilling.cardNumber.slice(-4);
      const maskedDigits = "X".repeat(this.getBilling.cardNumber.length - 8);
      const cardNumber = `${first4}${maskedDigits}${last4}`;
      const getBilling = this.getBilling;
      return {
        getBilling,
        cardNumber,
      };
    },
  },
  methods: {
    edit() {
      return this.$router.go(-1);
      //   return this.$router.push(`/payment/202304-00005/billing`);
    },
    makePayment() {
      //return this.$router.go(-1);
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
        return this.$router.push(`/payment/202304-00005/success`);
      }, 2000);
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
</style>
